<template>
    <div class="card-item">
        <div :class="`card-item-container ${item.class}`">
            <div
                class="card-item-container-body p-x-small-28"
                :data-aos="item.aos.content"
                data-aos-easing="linear"
            >
                <div class="card-item-content m-y-36 m-b-small-0 m-t-small-16">
                    <div class="m-b-16 m-b-small-8">
                        <Heading
                            v-if="item.headline"
                            color="accent-2nd"
                            class="f-w-700 f-s-20 l-h-24 m-b-8"
                            tag="p"
                            size="web-xx-sm"
                            style="letter-spacing: -0.5px; color: #009B8E;"
                        >
                            {{ item.headline }}
                        </Heading>
                        <Heading
                            color="primary"
                            class="f-w-700 m-0"
                            tag="h3"
                            size="web-x-lg"
                        >
                            {{ item.title }}
                        </Heading>
                    </div>

                    <Texture
                        tag="p"
                        size="lg"
                        color="secondary"
                        class="f-w-400 m-b-0"
                    >
                        {{ item.content }}
                    </Texture>

                    <div v-if="item.extra" class="m-t-16">
                        <img
                            class="m-x-8"
                            :src="$image(img.src)"
                            v-for="(img, i) in item.extra"
                            :key="i"
                        >
                    </div>

                    <div v-if="item.url" class="m-t-16">
                        <nuxt-link
                            :title="item.imageAlt || item.title"
                            :to="item.url"
                            class="btn md font f-s-16 l-h-20 f-w-700 d-inline-flex justify-content-start"
                            :class="item.isSpecialButton ? 'black' : 'plain p-0'"
                            style="text-align: start;"
                        >
                            {{ item.buttonTitle }}
                            <span class="icon arrow-right size sm m-l-4" />
                        </nuxt-link>
                    </div>
                </div>
            </div>

            <div
                class="card-item-container-body p-x-small-28"
                :data-aos="item.aos.image"
                data-aos-easing="linear"
            >
                <div class="card-item-content">
                    <img class="card-item-image w-100" :alt="item.imageAlt || item.title" :src="$image(item.image)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" scoped>
.card-item {
    margin-bottom: 100px;

    &-container {
        display: flex;
        flex-flow: column-reverse;
        align-items: stretch;
    }

    &-content {
        width: 100%;
    }

    &-image {
        background-size: cover;
        background-position: center;
        min-height: 240px;
        background-color: #fbf8f3;
        border-radius: 16px;
        overflow: hidden;
        // display: flex;
        // align-content: stretch;
    }
}

@media only screen and (min-width: 768px) {
    .card-item {
        &-image {
            min-height: 350px;
        }
    }
}

@media only screen and (min-width: 992px) {
    .card-item {
        &-image {
            min-height: 350px;
            object-fit: cover;
        }

        &-content {
            max-width: 532px;
            width: 100%;
        }

        &-container {
            flex-flow: unset;

            &.nth-reverse {
                flex-flow: row-reverse;
                padding: 0px !important;

                .card-item-container-body {
                    &:nth-child(1) {
                        padding-left: 23px !important;
                        padding-right: 0px !important;
                    }

                    &:nth-child(2) {
                        padding-right: 23px !important;
                        padding-left: 0px !important;
                    }
                }
            }

            justify-content: space-between;

            &-body {
                justify-content: center;
                display: flex;
                align-items: center;
                width: calc(100% / 2);

                &:nth-child(1) {
                    padding-right: 23px;
                }

                &:nth-child(2) {
                    padding-left: 23px;
                }
            }
        }
    }

    // &.nth-reverse-none {
    //     flex-flow: unset;
    // }
}
</style>
